<div class="modal-header">
  <h5 class="modal-title">Campaign Assignment</h5>
  <button type="button" class="close clickable" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <app-error></app-error>

  <div class="accordion" id="campaignAccordion">
    <!-- Campaign Section -->
    <div class="accordion-item">
      <h2 class="accordion-header" id="campaignHeader">
        <div class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#campaignCollapse">
          Campaign
        </div>
      </h2>
      <div id="campaignCollapse" class="accordion-collapse collapse show" data-bs-parent="#campaignAccordion">
        <div class="accordion-body">
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">CID</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="cid"
                     [readOnly]="!isAddingNew()" [(ngModel)]="assignment.cid" placeholder="Default">
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">AFID</label>
            <div class="col-sm-8">
              <input type="text" class="form-control" name="afid"
                     [readOnly]="!isAddingNew()" [(ngModel)]="assignment.afid" placeholder="Default">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Pricing Section -->
    <div class="accordion-item">
      <h2 class="accordion-header" id="pricingHeader">
        <div class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#pricingCollapse">
          Pricing
        </div>
      </h2>
      <div id="pricingCollapse" class="accordion-collapse collapse show" data-bs-parent="#campaignAccordion">
        <div class="accordion-body">
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Billing Scheme</label>
            <div class="col-sm-8">
              <select name="cascade" class="form-control"
                      [(ngModel)]="assignment.scheme_id" (change)="onBillingSchemeChange()">
                <option [value]="co.bsid" *ngFor="let co of scheme$ | async">{{co.internal_desc}} [{{co.bsid}}]</option>
              </select>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Subscription Package</label>
            <div class="col-sm-8">
              <app-package-selector [val]="assignment.package"
                                    (packageChange)="onPackageChange($event)"
                                    [class.is-invalid]="isPackageInvalid"
                                    (change)="onBillingSchemeChange()">
              </app-package-selector>
              <div class="invalid-feedback" *ngIf="isPackageInvalid">
                Price point doesn't match subscription package.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Features Section -->
    <div class="accordion-item">
      <h2 class="accordion-header" id="featuresHeader">
        <div class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#featuresCollapse">
          Features
        </div>
      </h2>
      <div id="featuresCollapse" class="accordion-collapse collapse show" data-bs-parent="#campaignAccordion">
        <div class="accordion-body">
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">
              Broken Enrollment
              <i class="fas fa-question-circle" ngbTooltip="KBA questions will be asked after account creation from inside the portal, NOT during signup" container="body"></i>
            </label>
            <div class="col-sm-8">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="broken_enrollment"
                       [(ngModel)]="assignment.broken_enrollment">
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Free Dine Membership</label>
            <div class="col-sm-8">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="dine_membership"
                       [(ngModel)]="assignment.dine_membership">
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">
              Deferred Auth
              <i class="fas fa-question-circle" ngbTooltip="Don't ask for any payment information (card or bank) during signup. Only for prepaid partners." container="body"></i>
            </label>
            <div class="col-sm-8">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="deferred_auth"
                       [(ngModel)]="assignment.deferred_auth">
              </div>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Max Darkweb Emails</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" name="darkweb_emails"
                     [(ngModel)]="assignment.darkweb_emails">
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-4 col-form-label">Dark Web Initial Phone</label>
            <div class="col-sm-8">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" name="darkweb_init_phone"
                       [(ngModel)]="assignment.darkweb_init_phone"
                       [disabled]="assignment.package === 'club-2024'">
                <small *ngIf="assignment.package === 'club-2024'" class="text-muted">
                  Only available for Extra and Max packages
                </small>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary" (click)="save()">Save</button>
  <button type="button" class="btn btn-outline-secondary" (click)="close()">Close</button>
</div>
