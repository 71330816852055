import {Component } from '@angular/core';
import {CommonModule} from '@angular/common';
import {CampaignsService} from '../../services/campaigns.service';
import {CampaignPriceModel} from '../../models/campaign-price.model';
import {NgbModal, NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {CampaignModifyModalComponent} from './campaign-modify-modal/campaign-modify-modal.component';
import {UserService} from 'src/app/shared/auth/services/user.service';
import {CampaignListGridComponent} from './components/campaign-list-grid/campaign-list-grid.component';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss'],
  imports: [CampaignListGridComponent, CommonModule, NgbTooltip]
})
export class CampaignListComponent {

  public campaigns: Array<CampaignPriceModel>;

  constructor(
    campaignsService: CampaignsService,
    private modalService: NgbModal,
    public userService: UserService,
  ) {
    campaignsService
      .get()
      .subscribe(res => {
        this.campaigns = res;
      });
  }

  openModal(price: CampaignPriceModel = null): void {
    const modalRef = this.modalService.open(CampaignModifyModalComponent, {size: 'lg'});

    if (price) {
      modalRef.componentInstance.priceId = price.price_id;
      modalRef.componentInstance.assignment.cid = price.cid;
      modalRef.componentInstance.assignment.afid = price.afid;
      modalRef.componentInstance.assignment.broken_enrollment = price.broken_enrollment;
      modalRef.componentInstance.assignment.cardless_auth = price.cardless_auth;
      modalRef.componentInstance.assignment.dine_membership = price.dine_membership;
      modalRef.componentInstance.assignment.deferred_auth = price.deferred_auth;
      modalRef.componentInstance.assignment.package = price.package;
      modalRef.componentInstance.assignment.darkweb_emails = price.darkweb_emails;
      modalRef.componentInstance.assignment.scheme_id = price.bsid;
      modalRef.componentInstance.assignment.darkweb_init_phone = price.darkweb_init_phone;
    } else {
      modalRef.componentInstance.assignment.broken_enrollment = true;
    }

    modalRef.result.then((resp: CampaignPriceModel | null) => {
      // If an update was made, splice the old one out of the array and replace it with the new object.
      if (resp != null) {
        const priceIndex = this.campaigns.indexOf(price);
        this.campaigns.splice(priceIndex, 1);
        this.campaigns.push(resp);

        // Recreate the array.  This will trigger ag-grid's change detection
        this.campaigns = [...this.campaigns];
      }
    });
  }

  handleRowAction(campaign: CampaignPriceModel): void {
    this.openModal(campaign);
  }
}
